<script>
  import { get } from 'vuex-pathify'

  export default {
    computed: {
      ...get('achievements', ['getAchievementsForCategory']),
    },
    methods: {
      goBack() {
        this.$navigate({ name: 'Home' })
      },
    },
  }
</script>

<template>
  <BaseContainer max-width="lg">
    <div class="mt-5">
      <BaseIconClickable label="Back" color="black" @click="goBack">
        <IconArrowLeft />
      </BaseIconClickable>
    </div>

    <div class="text-center pb-5">
      <h1 class="text-5xl font-bold leading-3">Alltrue Achievements</h1>
      <div>Earn and explore achievements by tapping the badges below!</div>
    </div>

    <BaseDivider />

    <section class="py-5">
      <BaseHeading class="text-center" size="h4">Upgrades</BaseHeading>
      <article class="flex flex-wrap justify-around">
        <figure
          v-for="achievement in getAchievementsForCategory('upgrades')"
          :key="achievement.label"
          class="w-20 text-center my-5 flex-shrink-0"
        >
          <BaseLink :to="{ name: 'AccountAchievement', params: { achievement: achievement.slug } }">
            <component :is="achievement.icon" class="w-17 m-auto" />
            <figcaption class="w-20 text-xs">{{ achievement.label }}</figcaption>
          </BaseLink>
        </figure>
      </article>
    </section>

    <BaseDivider />

    <section class="py-5">
      <BaseHeading class="text-center" size="h4">Anniversaries</BaseHeading>
      <article class="flex flex-wrap justify-around">
        <figure
          v-for="achievement in getAchievementsForCategory('anniversaries')"
          :key="achievement.label"
          class="w-20 text-center my-5 flex-shrink-0"
        >
          <component :is="achievement.icon" class="w-17 m-auto" />
          <figcaption class="w-20 text-xs">{{ achievement.label }}</figcaption>
        </figure>
      </article>
    </section>

    <BaseDivider />

    <section class="py-5">
      <BaseHeading class="text-center" size="h4">Coming Soon</BaseHeading>
      <article class="flex flex-wrap justify-around">
        <figure
          v-for="achievement in getAchievementsForCategory('coming-soon')"
          :key="achievement.label"
          class="w-20 text-center my-5 flex-shrink-0"
        >
          <component :is="achievement.icon" class="w-17 m-auto" />
          <figcaption class="w-20 text-xs">{{ achievement.label }}</figcaption>
        </figure>
      </article>
    </section>
  </BaseContainer>
</template>
